import { Title } from '@solidjs/meta';
import { Label, Switch } from '@troon/ui';
import { StyleCard } from '../_style-card';

export default function SwitchPage() {
	return (
		<>
			<Title>Switch | Forms | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Switch</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="Switch">
					<Switch name="switch">
						<Label>Toggle switch</Label>
					</Switch>
				</StyleCard>
			</div>
		</>
	);
}
